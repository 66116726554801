@import 'reset-css';
@import './fonts.scss';
@import '~hamburgers/dist/hamburgers.min.css';

@mixin row ($margin: 15px) {
    margin-right: -$margin;
    margin-left: -$margin;
    display: flex;
    flex-wrap: wrap;
}

@mixin col ($count, $margin: 15px, $grid-size: 12) {
    width: calc(100% / #{$grid-size} * #{$count} - #{$margin} * 2);
    margin-left: $margin;
    margin-right: $margin;
}
$color-grey: #00152D;
$color-green: #1EC111;
$color-cyan: #004064;

img {
    max-width: 100%;
}

body {
    overflow-x: hidden;
    font-family: Roboto;
    font-size: 0;
    * {
        box-sizing: border-box;
    }
    
}

.wrapper {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

.hamburger {
    padding: 0px;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    z-index: 101;
    position: fixed;
    top: 25px;
    right: 15px;
    @media (min-width: 769px) {
        display: none;
    }
}
.hamburger-box {
    width: 23px;
}

.hamburger-inner {
    height: 1.5px;
    width: 23px;
    background: rgba(0, 21, 45, 0.6) !important;
    &:before {
        height: 1.5px;
        width: 23px;
        background: rgba(0, 21, 45, 0.6) !important;
        top: -8px;
    }
    &:after {
        width: 23px;
        height: 1.5px;
        background: rgba(0, 21, 45, 0.6) !important;
        bottom: -8px;
    }
}

.header {
    padding-top: 26px;
    margin-bottom: 70px;
    @media (max-width: 768px) {
        margin-bottom: 50px;
    }
    &__wr {
        display: flex;
        justify-content: space-between;
    }
    &__logo-wr {
        display: inline-block;
        margin-right: 120px;
        @media (max-width: 768px) {
            margin-right: 0;
            box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.16);
            border-radius: 24px;
            padding: 12px 48px;
            position: relative;
            z-index: 101;
            background: #fff;
        }
        flex: 0 0 auto;
    }
    &__logo-link {
        display: block;
    }
    &__row {
        background: #FFFFFF;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.16);
        border-radius: 24px;
        padding: 12px 48px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            display: inline-flex;
            box-shadow: none;
            padding: 0;
        }
    }
}

.header-menu {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        display: none;
        background: linear-gradient(173.9deg, rgba(210, 255, 234, 0.94) 3.77%, rgba(119, 255, 230, 0.85) 100.73%);
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 32px;
        flex-direction: column;
    }
    &__item {
        font-size: 14px;
        margin-right: 40px;
        margin-top: 6px;
        margin-bottom: 6px;
        text-decoration: none;
        color: $color-grey;
        @media (max-width: 768px) {
            display: block;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
    &__item-link {
        font-size: 14px;
        margin-right: 40px;
        margin-top: 6px;
        margin-bottom: 6px;
        text-decoration: none;
        color: $color-grey;
        @media (max-width: 768px) {
            display: block;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}

.main {
    box-shadow: 0px 0px 40px rgba(0, 21, 45, 0.16);
    overflow: hidden;
}

.competition {
    margin-bottom: 128px;
    &__row {
        position: relative;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }
    &__img {
        @media (min-width: 769px) {
            position: absolute;
            top: 0;
            right: -22%;
            z-index: 0;
        }

    }
    &__text {
        position: relative;
        z-index: 1;
        margin-top: 20px;
        max-width: 465px;
        font-size: 18px;
        line-height: 1.2;
        color: $color-cyan;
        order: 2;
        @media (max-width: 768px) {
            font-size: 14px;
            margin-bottom: 28px;
        }
    }
    &__rotate-text {
        position: relative;
        z-index: 1;
        order: 1;
        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            width: 154px;
            height: 2px;
            background: $color-grey;
        }  
        @media (min-width: 769px) {
            display: inline-block;
            writing-mode: tb-rl;
            transform: rotate(180deg);
            margin-right: 106px;
            padding-top: 42px;  
        }
        @media (max-width: 768px) {
            padding-bottom: 32px;
            &:before {
                top: auto;
                bottom: 0;
                left: 0;
                width: 113px;
            }
        }
    }
    &__caption {
        font-family: RotondaC;
        font-size: 114px;
        color: $color-grey;
        @media (min-width: 769px) {
            margin-left: 10px; 
        }
        @media (max-width:768px) {
            font-size: 14.5vw; 
        }
    }
    &__subtitle {
        font-family: RotondaC;
        font-size: 53px;
        color: $color-green;
        @media (max-width:768px) {
            font-size: 7vw; 
        }
    }
    &__block {
        display: inline-block;
        vertical-align: top;
        order: 3;
        @media (max-width:768px) {
            box-shadow: 0px -24px 24px rgba(0, 21, 45, 0.05);
            margin-left: -15px;
            margin-right: -15px;
            padding: 0 15px;
        }
    }
    &__sum-block {
        position: relative;
        z-index: 1;
        margin-bottom: 44px;
        @media (max-width:768px) {
            margin-bottom: 26px;
        }
    }
    &__sum-caption {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        color: $color-grey;
        margin-bottom: 10px;
        @media (max-width:768px) {
            text-align: center;
            margin-top: 34px;
        }
    }
    &__sum {
        color: $color-green;
        font-size: 48px;
        font-weight: 700;
        font-family: RotondaC;
        @media (max-width:768px) {
            text-align: center;
            font-size: 36px;
        }
    }
    &__timer-block {
        position: relative;
        z-index: 1;
        margin-bottom: 30px;
    }
    &__timer-text {
        font-weight: 300;
        font-size: 18px;
        line-height: 1.2;
        color: $color-grey;
        margin-bottom: 20px;
        @media (max-width:768px) {
            font-size: 14px;
            text-align: center;
        }
    }
    &__button {
        position: relative;
        z-index: 1;
        cursor: pointer;
        display: inline-block;
        font-family: RotondaC;
        font-size: 18px;
        color: #FFFFFF;
        background: $color-green;
        box-shadow: 0px 0px 22px rgba(36, 255, 0, 0.4);
        border-radius: 30px;
        padding: 17px 53px;
        text-decoration: none;
    }
    &__button-wr {
        text-align: center;
    }
}

.timer {
    display: flex;
    @media (max-width:768px) {
        justify-content: center;
    }
    &__col {
        margin-right: 24px;
        @media (max-width: 768px) {
            margin-right: 18px;
        }
        &:nth-child(3) {
            margin-right: 0;
        }
        &:last-child {
            margin-right: 18px;
            display: none;
        }
    }
    &__value {
        position: relative;
        width: 38px;
        height: 64px;
        @media (max-width: 768px) {
            width: 24px;
            height: 36px;
        }
    }
    &__digit {
        position: absolute;
    }
    &__block {
        padding: 16px;
        width: 108px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: RotondaC;
        font-weight: 700;
        font-size: 64px;
        color: $color-grey;
        letter-spacing: 0.03em;
        border: 1px solid #D3D3D3;
        border-radius: 12px;
        margin-bottom: 10px;
        overflow: hidden;
        @media (max-width: 768px) {
            width: 81px;
            height: 66px;
            padding: 14px;
            font-size: 39px;
        }
    }
    &__label {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;
    }
}

.terms {
    margin-bottom: 90px;
    &__row {
        @media (min-width:769px) {
            @include row(27px)
        }
    }
    &__caption {
        font-family: RotondaC;
        @media (min-width:769px) {
            writing-mode: tb-rl;
            transform: rotate(180deg);
            @include col(1, 27px);
            
        }
        font-size: 46px;
        line-height: 1.3;
        @media (max-width: 768px) {
            font-size: 36px;
            text-align: center;
            margin-bottom: 48px;
        }
    }
    &__block {
        background: #FFFFFF;
        box-shadow: 0px 0px 32px rgba(3, 0, 19, 0.16);
        border-radius: 20px;
        padding: 40px;
        @media (max-width: 768px) {
            margin-bottom: 32px;
            padding: 34px 24px;
        }
        &_small {
            @media (min-width:769px) {
                @include col(5, 27px)
            }
        }
        &_big {
            @media (min-width:769px) {
                @include col(6, 27px)
            }
        }
    }
    &__block-caption {
        font-family: RotondaC;
        font-weight: 700;
        font-size: 32px;
        line-height: 1.2;
        color: $color-green;
        margin-bottom: 25px;
        @media (max-width: 768px) {
            font-size: 26px;
        }
    }
    &__list {

    }
    &__list-item {
        padding-left: 30px;
        position: relative;
        margin-bottom: 12px;
        font-size: 18px;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            display: block;
            width: 13px;
            height: 9px;
            background: url('/public/img/list-arrow.svg') center center no-repeat;
            
        }
    }
}

.organizers {
    margin-bottom: 140px;
    @media (max-width: 768px) {
        margin-bottom: 80px;
    }
    &__caption {
        font-size: 36px;
        font-weight: 700;
        color: $color-grey;
        margin-bottom: 50px;
        text-align: center;
        font-family: RotondaC;
        @media (max-width: 768px) {
            font-size: 28px;
        }
    }
    &__row {
        @include row(50px);
        justify-content: center;
        align-items: center;
    }
    &__img-wr {
        margin-right: 50px;
        margin-left: 50px;
        margin-bottom: 44px;
    }
    &__img {
        // &_mincom {
        //     max-width: 100px;
        // }
    }
}
.partners {
    margin-bottom: 200px;
    @media (max-width: 768px) {
        margin-bottom: 100px;
    }
    &__caption {
        font-family: RotondaC;
        font-size: 46px;
        line-height: 1.3;
        @media (min-width: 769px) {
            writing-mode: tb-rl;
            transform: rotate(180deg);
            margin-right: 27px;
        }
        @media (max-width: 768px) {
            font-size: 36px;
            text-align: center;
            margin-bottom: 48px;
        }
    }
    &__row {
        @media (min-width: 769px) {
            display: flex;
            align-items: flex-start;
        }
    }
    &__wr {
        @media (max-width: 768px) {
            overflow: hidden;
            margin-left: -30px;
        }
    }
    &__items {
        @media (min-width: 769px) {
            @include row(35px);
        }
        @media (max-width: 768px) {
            overflow-x: scroll;
            overflow-y: hidden;
            margin-bottom: -20px;
            white-space: nowrap;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &__item-wr {
        margin-left: 35px;
        margin-right: 35px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        @media (min-width: 769px) {
            filter: grayscale(1);
            &:hover {
                transform: scale(1.2);
            }
        }
        transition: 0.3s;
        &:hover {
            filter: none;
        }
        @media (max-width: 768px) {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;
            display: inline-flex;
            vertical-align: middle;
        }
    }
}

.request {
    padding-bottom: 96px;
    &__row {
        @media (min-width: 769px) {
            @include row(27px);
            align-items: flex-start;
        }
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }
    &__caption {
        font-size: 46px;
        line-height: 1.3;
        font-family: RotondaC;
        @media (min-width: 769px) {
            writing-mode: tb-rl;
            transform: rotate(180deg);
            @include col(1, 27px);
        }
        @media (max-width: 768px) {
            font-size: 36px;
            text-align: center;
            margin-bottom: 48px;
            order: 1;
        }
    }
    &__block {
        &_small {
            @media (min-width: 769px) {
                @include col(5, 27px)
            }
            @media (max-width: 768px) {
                order: 3;
            }
        }
        &_big {
            @media (min-width: 769px) {
                @include col(6, 27px)
            }
            @media (max-width: 768px) {
                order: 2;
                margin-bottom: 50px;
            }
            
        }
    }
    &__img-wr {
        @media (max-width: 768px) {
            display: none;
        }
    }
    &__img {
        margin-bottom: 38px;
    }
    &__label {
        font-family: RotondaC;
        font-weight: bold;
        font-size: 22px;
        color: $color-grey;
        margin-bottom: 32px;
    }
    &__list-item {
        padding-left: 30px;
        position: relative;
        margin-bottom: 12px;
        font-size: 18px;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            display: block;
            width: 13px;
            height: 9px;
            background: url('/public/img/list-arrow.svg') center center no-repeat;
            
        }
    }
}

.request-form {
    &__label {
        font-family: RotondaC;
        font-weight: bold;
        font-size: 22px;
        color: $color-grey;
        margin-bottom: 32px;
    }
    &__input-wr {
        margin-bottom: 32px;
        position: relative;
    }
    &__input {
        background: #FFFFFF;
        border-radius: 25px;
        padding: 16px 24px;
        border: 0;
        outline: 0;
        width: 100%;
        font-size: 16px;
        text-align: left !important;
        &::placeholder  {
            color: rgba(0, 15, 32, 0.45);
        }
    }
    &__shadow-wr {
        box-shadow: 0px 0px 24px rgba(3, 0, 19, 0.16);
        border-radius: 25px;
        position: relative;
        &_focus {
            .request-form__input-label {
                pointer-events: none;
            }
            .request-form__input-label-text {
                transform: translate(0);
                top: 3px;
                font-size: 12px;
            }
        }
    }
    &__input-label {
        font-size: 24px;
        color: rgba(0, 15, 32, 0.45);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-left: 16px;
        transition: transform .3s ease, font-size .3s ease, color .3s ease;
        cursor: text;
    }
    &__textarea-label {
        font-size: 16px;
        margin-bottom: 10px;
        color: rgba(0, 15, 32, 0.45);
        display: block;
    }
    &__input-label-text {
        position: absolute !important;
        top: 50%;
        left: 24px;
        font-size: 16px;
        transform: translate(0, -50%);
        transition: all 0.3s;
        line-height: 1;
    }
    &__textarea {
        font-size: 16px;
        height: 114px;
        background: #FFFFFF;
        border-radius: 25px;
        border: 0;
        outline: 0;
        width: 100%;
        // margin-bottom: 32px;
        resize: none;
        padding: 16px 24px;
        &::placeholder  {
            color: rgba(0, 15, 32, 0.45);
        }
    }
    &__text {
        font-size: 16px;
        line-height: 1.2;
        color: #000F20;
        margin-bottom: 20px;
    }
    &__checkbox-wr {
        margin-bottom: 20px;
        input {
            display: none;
            &:checked {
                +.request-form__checkbox-label {
                    &::before {
                        background: url('/public/img/check.svg') center center no-repeat,$color-green;
                    }
                }
            }
        }
    }
    &__checkbox-label {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        &::before {
            content: '';
            display: block;
            flex: 0 0 auto;
            width: 26px;
            height: 26px;
            background: #FFFFFF;
            box-shadow: 0px 0px 24px rgba(3, 0, 19, 0.2);
            border-radius: 8px;
            margin-right: 16px;
        }
    }
    &__link {
        color: $color-green;
        text-decoration: none;
        transition: color 0.3s;
        &:hover {
            color: darken($color: $color-green, $amount: 10%);
        }
    }
    &__button-wr {
        @media (max-width: 768px) {
            text-align: center;
        }
    }
    &__button {
        background: $color-green;
        box-shadow: 0px 0px 16px rgba(36, 255, 0, 0.5);
        border-radius: 30px;
        display: inline-block;
        border: 0;
        outline: 0;
        font-size: 16px;
        font-family: RotondaC;
        color: #fff;
        padding: 13px 30px;
        cursor: pointer;
        margin-top: 48px;
    }
    &__error-message {
        .error {
            font-size: 16px;
            color: #ff3860 !important; 
        }
        margin-top: 10px;
        display: block;
    }
    &__suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        box-shadow: 0 0 10px rgba(3,0,19,.16);
        z-index: 1;
        border-radius: 25px;
        overflow: hidden;
        display: none;
    }
    &__suggestion-item {
        font-size: 16px;
        color: $color-grey;
        padding: 12px 24px 12px 24px;
        cursor: pointer;
        background: #fff;
    }
    &__hint {
        font-size: 14px;
        color: rgba(0, 15, 32, 0.45);
        margin-top: 10px;
    }
    &__hideText {
        font-size: 14px;
        color: $color-grey;
        line-height: 1.2;
        padding-top: 20px;
        display: none;
        p {
            margin-bottom: 10px;
        }
        .bold {
            font-weight: 700;
        }
        ul {
            list-style-type: disc;
            padding-left: 17px;
            li {
                margin-bottom: 10px;
            }
        }
    }
}

.footer {
    padding:  48px 0;
    &__phones {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
    }
    &__social {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
    }
    &__copy {
        text-align: center;
    }
    &__copy-text {
        font-family: Roboto;
        font-weight: 300;
        font-size: 14px;
        line-height: 1.5;
        color: #00152D;
        opacity: 0.8;
        a {
            text-decoration: none;
            color: #00152D;
            transition: 0.3s;
            &:hover {
                color: $color-green;
            }
        }
    }
    &__icon-link {
        filter: grayscale(1);
        transition: 0.3s;
        margin: 0 8px;
        &:hover {
            filter: none;
        }
    }
    &__text {
        font-weight: 300;
        font-size: 18px;
        color: #00152D;
        opacity: 0.8;
        margin-bottom: 14px;
    }
    &__phone {
        font-family: RotondaC;
        font-weight: 700;
        font-size: 26px;
        line-height: 1.3;
        color: #00152D;
        opacity: 0.9;
        text-decoration: none;
    }
    &__icons {
        display: flex;
        align-items: center;
    }
}

.preloader {
    display: none;
    position: absolute;
    &_fixed {
        position: fixed;
    }
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255,255,255,1);
    &_transparent {
        opacity: 0.5;
    }
  }

  &__wr {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__logo-wr {
    max-width: 200px;
    margin-top: 15px;
    user-select: none;
  }

  &__logo {
    width: 100%;
  }

  &__spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom: 3px solid #50b848;
    border-top: 3px solid #50b848;
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}